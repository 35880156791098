@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  flex: 1 1;
  height: 100%;
  padding: 20px 0 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }

  .home-content {
    overflow-y: hidden;
  }
}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 20px 0;

    .home-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
