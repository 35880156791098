@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.setting-page {
  flex: 1 1;
  padding: 20px 0;

  .setting-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

.region-settings {
  h4 {
    margin-bottom: 8px;
  }

  .region-settings-form {
    display: grid;
    grid-template-columns: 1fr 80px 50px;
    grid-gap: 12px;
    align-items: center;

    & > p {
      margin-bottom: 8px;
      font-weight: 600;
    }
  }
}

.setting-forms {
  margin-top: 20px;

  & > h4 {
    margin: 12px 0 8px 0;
  }

  .setting-forms-rows {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 12px;

    .input-wrap label {
      max-width: 100%;
    }
  }
}

@media all and (max-width: $ipad) {
  .setting-page {
    padding: 20px 0;

    .setting-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
