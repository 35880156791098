@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.card-msg {
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;

  .card-avatar {
    margin-right: 12px;
  }

  .card-msg-wrap {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 8px;
    border-radius: 4px;
    background-color: $ui-bg-light;

    p {
      color: $ui-text-dark;
      font-size: 12px;
    }

    .card-msg-sender {
      margin-bottom: 4px;

      p {
    
        color: $ui-text-dark-gray;
        font-weight: 500;
      }
    }

    .card-msg-info {
      p {
        color: $ui-text-dark;
      }
    }
  }
}
