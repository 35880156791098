@import "./colors.scss";
@import "./media.scss";

.app {
  overflow: hidden;
  background-color: $ui-bg-light;

  .app-container {
    flex-grow: 1;
    flex-shrink: 1;
    grid-template-columns: 60px 1fr;
    grid-gap: 20px;
    overflow: hidden;

    .app-content {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0;
      overflow: hidden;
      overflow-y: auto;
    }

    &.full-container {
      grid-template-columns: 1fr;

      .app-content {
        grid-template-columns: 1fr;
        padding: 0;
      }
    }
  }
}

@media all and (max-width: $macbook) {
  .app {
    .app-container {
      .app-content {
        grid-template-columns: 1fr;
        padding-right: 20px;
      }

      &.full-container {
        .app-content {
          padding-right: 0;
        }
      }
    }
  }
}

@media all and (max-width: $desktop) {
  .app {
    .app-container {
      &.full-container {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .app {
    .app-container {
      grid-template-columns: 1fr;
      padding-bottom: 126px;

      &.full-container {
        padding-bottom: 0;
      }
    }
  }
}

@media all and (max-width: $mobile) {
  .app {
    .app-container {
      padding-bottom: 84px;

      .app-content {
        padding-right: 26px;
        padding-left: 26px;
      }

      &.full-container {
        padding-bottom: 0;

        .app-content {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

@media all and (max-width: $small) {
  .app {
    .app-container {
      .app-content {
        padding-right: 20px;
        padding-left: 20px;
      }

      &.full-container {
        .app-content {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}
