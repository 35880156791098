$black: #000;
$darkGray: #444;
$light: #f5f6fa;
$lightGray: #edeef2;
$white: #fff;

/* cards color */
$red: #d50000;
$pink: #c51162;
$purple: #aa00ff;
$violet: #6200ea;
$primary: #304ffe;
$blue: #2962ff;
$sky: #0091ea;
$cyan: #00b8d4;
$teal: #00bfa5;
$grass: #00c853;
$green: #64dd17;
$lime: #aeea00;
$yellow: #ffd600;
$amber: #ffa231;
$orange: #ff6d00;
$brick: #dd2c00;
$gray: #757575;
$dark: #222;

/* COLORS CONSTANTS */
/* background colors */
$ui-bg-dark: $dark;
$ui-bg-gray: $lightGray;
$ui-bg-light: $light;
$ui-bg-white: $white;

/* button colors */
$ui-btn-blue: $blue;
$ui-btn-dark: $darkGray;
$ui-btn-orange: $amber;
$ui-btn-primary: $primary;
$ui-btn-white: $white;

/* gradient colors */
$ui-grad-start: $dark;
$ui-grad-end: $dark;

/* icon colors */
$ui-icon-amber: $amber;
$ui-icon-gray: $gray;
$ui-icon-light: $light;
$ui-icon-menu: $gray;
$ui-icon-primary: $primary;
$ui-icon-red: $red;
$ui-icon-orange: $orange;
$ui-icon-white: $white;

/* line colors */
$ui-line-dark: $dark;
$ui-line-gray: $lightGray;
$ui-line-header: $darkGray;
$ui-line-table: $light;

/* shadow colors */
$ui-shadow: $darkGray;
$ui-shadow-table: $lightGray;

/* text colors */
$ui-text-amber: $amber;
$ui-text-blue: $blue;
$ui-text-dark-blue: $amber;
$ui-text-dark: $dark;
$ui-text-gray: $gray;
$ui-text-green: $grass;
$ui-text-dark-gray: $gray;
$ui-text-light: $light;
$ui-text-menu: $gray;
$ui-text-orange: $orange;
$ui-text-primary: $primary;
$ui-text-red: $red;
$ui-text-search: $darkGray;
$ui-text-soft-blue: $amber;
$ui-text-white: $white;

/* transparent colors */
