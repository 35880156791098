@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.button-wrap {
  position: relative;
  width: 100%;
}

.button {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  border-radius: 4px;
  border: 1px solid $ui-btn-orange;
  background-color: $ui-btn-orange;

  &.small {
    width: auto;
    height: 32px;
    padding: 0 16px;
    border-radius: 20px;

    span {
      text-transform: none;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.button-blue {
  border: 1px solid $ui-btn-blue;
  background-color: $ui-btn-blue;

  span {
    color: $ui-text-light;
  }
}

.button-orange {
  border: 1px solid $ui-btn-orange;
  background-color: $ui-btn-orange;

  span {
    color: $ui-text-light;
  }
}

.button-primary {
  border: 1px solid $ui-btn-primary;
  background-color: $ui-btn-primary;

  span {
    color: $ui-text-light;
  }
}

.button-white {
  border: 1px solid $ui-line-gray;
  background-color: $ui-btn-white;

  span {
    color: $ui-text-dark;
  }
}
